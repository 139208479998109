.loading-overlay {
  font-weight: 300;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.4s ease;
  z-index: $loading-overlay;
  opacity: 1;

  .loading-wrapper {
    position: fixed;
    left: 50%;
    top: 40%;
    text-align: center;
    width: 100%;
    margin: 0 0 0 -50%;
    height: 100%;
    opacity: 1;

    span {
      color: $offwhite;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }

  &.hidden {
    transition: opacity 0.4s ease, z-index 0.1s 0.4s;
    opacity: 0;
    z-index: $hidden;
  }
}

/*
https://github.com/tobiasahlin/SpinKit/blob/master/LICENSE
*/
.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 10px auto;

  &.small {
    width: 15px;
    height: 15px;
    margin: 5px 20px 5px 10px;
  }
}

.double-bounce,
.double-bounce-overlay {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $offwhite;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 3s infinite ease-in-out;
  animation: sk-bounce 3s infinite ease-in-out;
}

.double-bounce-overlay {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background-color: black;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0.3);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0.3);
    -webkit-transform: scale(0.3);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.fixedTooSmallMessage {
  position: absolute;
  top: 0;
  color: white;
  padding: 10px;
}
