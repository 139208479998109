a {
  color: $yellow !important;
}

.mo-overlay {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  &.opaque {
    background-color: black;
  }
  z-index: 20;
}

.mo-container {
  margin-top: $banner-height;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60vw;
  max-width: 1500px;
  box-shadow: 0 19px 19px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  overflow: auto;
  z-index: $overheader;
}

$overlay-bg: rgba(239, 239, 239, 0.9);

.mo-banner {
  position: fixed;
  min-height: 100px;
  color: $offwhite;
  background-color: transparent;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;

  .mo-banner-close {
    position: fixed;
    top: 20px;
    left: 20px;
    min-width: $banner-height;
    width: $banner-height;
    .material-icons {
      font-size: 40pt;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s all ease;
      color: $overlay-bg;

      &:hover {
        text-decoration: none;
        cursor: pointer;
        color: white;
      }
    }
  }

  .mo-banner-content {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &.h3 {
      border-radius: 2px;
      padding: 10px 15px;
      background-color: transparent;
      color: $overlay-bg;
    }
  }
}

.banner-trans {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 2 * $banner-height;
  width: 2 * $banner-height;

  .trans-button {
    padding: 15px;
    margin: 10px;
    border: 1px solid $darkwhite;
    transition: 0.1s all ease;
    &:hover {
      background-color: $darkwhite;
      cursor: pointer;
    }
  }

  &.right-overlay {
    position: relative;
    width: 25%;
    float: right;
    justify-content: flex-end;
    z-index: $map;
    .trans-button {
      background-color: $black;
      &:hover {
        background-color: $darkwhite;
      }
    }
  }
}

.media-gallery-controls {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -50%;

  .back,
  .next {
    position: fixed;
    bottom: 0;
    height: 170px;
    background: transparent;
    color: $offwhite;
    cursor: pointer;
    box-shadow: 0 19px 19px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    svg path {
      fill: $offwhite;
    }
    z-index: 1;
  }

  .centerer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .material-icons {
    font-size: 40pt;
  }

  .back {
    left: 10px;
    svg path {
      transform: translate(17px, 15px) rotate(-90deg);
    }
  }
  .next {
    margin-left: calc(100% - 60px);
    right: 10px;
    svg path {
      transform: translate(17px, 15px) rotate(90deg);
    }
  }
}

.mo-media-container {
  flex: 1;
  flex-direction: row;
  justify-content: center;
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  max-height: calc(#{$panel-height} - 100px);

  .media-content {
    display: flex;
    flex-direction: column;
  }

  // NB: topcushion seems to be necessary with certain overflows..
  &.topcushion {
    padding-top: 150px;
  }
}

.mo-footer {
  position: fixed;
  // height: 250px;
  background-color: transparent;
  width: 100%;
  opacity: 0.9;
  bottom: 20px;
  display: flex;
  justify-content: center;
}

.mo-meta-container {
  color: $overlay-bg;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  min-height: 100px;

  .mo-box-desc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    max-width: $panel-width;
  }

  .mo-box {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-width: 800px;
    max-width: $panel-width;
    padding: $padding 0;
    border-top: 1px solid rgb(189, 189, 189);
    font-size: $normal;

    h4 {
      margin: 0 0 5px 0;
      text-transform: uppercase;
      font-size: $xsmall;
      color: $darkwhite;
      font-weight: 100;
    }

    p {
      margin-top: 0;
      font-size: $large;
    }

    .material-icons {
      font-size: $normal;
      color: $darkwhite;
      margin-right: 5px;
    }

    a {
      font-size: $large;
      color: $yellow;
      border-bottom: 1px solid $yellow;
    }
  }

  .indent {
    margin-left: 2 * $header-inset;
  }
}

/* source overlay specific styles */
.no-source-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid black;
  padding: 2em;
  min-height: 200px;
}

.no-source-row {
  p {
    text-align: center;
    color: $midgrey;

    .no-source-icon {
      font-size: $xxxlarge;
      color: $darkwhite;
    }
  }
}

.source-media-gallery {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  margin: 0;
  transition: transform 0.2s ease;
}

.source-text-container {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 calc(50% - 400px);
  overflow-y: scroll;
  line-height: 1.5em;
  min-width: 100%;
  margin-bottom: 120px;

  color: $overlay-bg;

  a {
    color: $yellow;
    border-bottom: 1px solid $yellow;
  }

  .md-container {
    width: 100%;
    overflow-wrap: break-word;
    white-space: pre-line;
  }
}

.source-image-container,
.media-player {
  display: flex;
  justify-content: center;
  padding: 20px;
  min-width: calc(100% - 40px);
  z-index: $final-level;
  max-height: 60vh;
}

.media-player {
  background-color: transparent;
  box-sizing: border-box;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  display: block;
}

.source-image,
.source-video {
  padding: 1px;
  max-height: 100%;
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  object-fit: contain;
  &:hover {
    cursor: pointer;
    background-color: $yellow;
  }
}

.source-image-loader {
  width: 400px;
  height: 400px;
}

.source-document {
  width: 100%;
  min-height: 80vh;
}

.video-react .video-react-progress-control {
  align-self: center;
}

.video-react .video-react-control {
  min-height: 100%;
}

// full-screen overloads
.mo-overlay.full-screen {
  background-color: black;
  .mo-container {
    background-color: transparent;
  }
  .mo-media-container {
    border-top: 1px solid $offwhite;
    border-bottom: 1px solid $offwhite;
  }
  .mo-box {
    border-color: transparent;
  }
}
