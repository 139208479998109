/*
NARRATIVE INFO
*/
.narrative-info {
  position: fixed;
  top: 30px;
  left: auto;
  right: $card-right; // looks a bit better due to the 1px border on other elements.
  width: $card-width;
  box-sizing: border-box;
  max-height: calc(100% - 250px);
  box-shadow: 0 19px 38px rgba($black, 0.3), 0 15px 12px rgba($black, 0.22);
  background: $black;
  color: $offwhite;

  .narrative-info-header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border-bottom: 1px solid $darkwhite;
    padding: 0 15px;

    .count-container {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid $darkwhite;
    }
    .count {
      position: relative;
      padding-right: 15px;
    }
  }

  .narrative-info-desc {
    max-height: $narrative-info-desc-height;
    overflow-y: auto;
    white-space: pre-line;
    padding-bottom: 5px;
  }

  p {
    padding: 0 15px 15px 15px;
  }

  h3,
  h6 {
    text-align: center;
  }

  h3 {
    font-size: $large;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 100;
  }

  h6 {
    margin: 10px 0;
    i {
      font-size: $normal;
    }
  }

  p {
    font-size: $normal;
    line-height: 1.4em;
  }

  .actions {
    width: 100%;
    .action {
      width: calc(50% - 5px);
      height: 40px;
      box-sizing: border-box;
      line-height: 40px;
      text-align: center;
      display: inline-block;

      &:not(.disabled) {
        &:hover {
          cursor: pointer;
          transition: 0.2s ease;
          color: $yellow;
        }
      }

      &.disabled {
        color: $midgrey;
        cursor: normal;
      }

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}

.narrative-adjust {
  position: fixed;
  bottom: $timeline-height;
  right: auto;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: $header;

  &.left {
    right: $card-right + $card-width - 40pt;
  }

  &.right {
    right: $card-right;
  }

  .material-icons {
    font-size: 40pt;
    color: $offwhite;
    transition: color 0.2s ease;

    &.disabled {
      display: none;
    }

    &:hover {
      cursor: pointer;
      color: $midwhite;
    }
  }
}

.narrative-close {
  display: flex;
  justify-content: flex-start;
  position: fixed;
  padding: 2px 5px 0 5px;
  right: $card-right;
  top: 5px;
  width: $card-width - 12px; // subtracting the extra width added by padding
  // width: 15px;
  background-color: black;
  height: 20px;
  transition: background-color 0.2s ease;
  border: 1px solid black;

  button {
    height: 15px;
    width: 15px;
  }

  .close-text {
    display: none;
    color: $midgrey;
    flex: 1;
    width: 100%;
    justify-content: center;
    font-size: 10pt;
  }

  // disable whitening of crosshair on hover
  button {
    span,
    span:before,
    span:after {
      background: $midwhite !important;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $offwhite;
    color: black;
    .close-text {
      display: flex;
    }
  }
}
