@import "burger";

.notification-wrapper {
  top: 60px;
  right: 60px;
  width: 400px;
  height: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.notification {
  width: 100%;
  min-height: 40px;
  box-shadow: 10px -10px 38px rgba(0, 0, 0, 0.3),
    10px 15px 12px rgba(0, 0, 0, 0.22);
  color: $darkgrey;
  background: $offwhite;
  border-radius: 5px;
  border: 3px solid $offwhite;
  padding: 20px;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-size: $large;
  transition: opacity 0.5s ease 0.1s, z-index 0.1s ease 0s;
  opacity: 1;
  z-index: $overheader;
  cursor: pointer;

  &:hover {
    background: lighten($offwhite, 5%);
    transition: background-color 0.4s;
  }

  &.hidden {
    transition: 0.5s ease;
    opacity: 0;
  }

  .side-menu-burg {
    position: absolute;
    right: 8px;
    top: 10px;
  }

  .message {
    display: inline-block;

    &.error {
      color: red;
    }
    &.warning {
      color: orange;
    }
    &.good {
      color: green;
    }
    &.neutral {
      color: $darkgrey;
    }
  }

  .details {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    margin-top: 10px;
    padding: 10px;
    background: $darkgrey;
    color: $offwhite;
    font-family: monospace;

    &.true {
      height: auto;
      transition: height 0.4s, margin 0.4s;
    }

    &.false {
      height: 0;
      padding: 0;
      margin: 0;
      transition: height 0.4s, margin 0.4s;
    }
  }
}
