.cover-container {
  position: absolute;
  top: -100%;
  left: 0;
  height: 100vh;
  background-color: black;
  width: 100%;
  opacity: 1;
  transition: top 0.4s ease;
  z-index: $loading-overlay + 1;
  overflow-y: auto;
  overflow-x: hidden;
  color: $offwhite;

  &.showing {
    top: 0;
    left: 0;
  }
}

.cover-header {
  position: fixed;
  bottom: 20px;
  left: 0;
  display: flex;
  width: 100vw;

  @media only screen and (max-width: 1200px) {
    position: inherit;
  }

  .cover-logo-container {
    padding: 20px 0 0 20px;
    display: flex;
    &.minimized {
    }
    .cover-logo {
      transition: all 1s;
      width: 60px;
      height: 60px;
    }
  }

  &.minimized {
    bottom: 150px;
    max-width: $toolbar-width;
    max-height: 30px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .cover-logo-container {
      padding: 5px;
    }
    .cover-logo {
      width: 60px;
      height: 60px;
    }
  }
}

.fullscreen-bg {
  &.hidden {
    top: -100%;
  }
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // overflow: hidden;
  z-index: -100;
  background: #000000;
}

.fullscreen-bg__video {
  position: relative;
  top: 0;
  left: -25vw;
  width: 150vw;
  height: 100vh;
  -webkit-filter: contrast(70%) brightness(70%) grayscale(100%);
  filter: contrast(70%) brightness(70%) grayscale(100%);
  @media only screen and (max-width: 992px) {
    display: none;
  }
}

.default-cover-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cover-container {
  font-size: 12pt;
  display: flex;
  flex-direction: column;
  max-height: 100%;

  hr,
  br {
    width: 100%;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: space-around;
    position: fixed;
    left: 0;
    background-color: $offwhite;
    margin-top: 60px;
    min-height: calc(100% - 280px);
    max-height: calc(100% - 280px);
    min-width: 19%;
    max-width: 19%;
    color: black;
    .il-video-pill {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex: 1;
      background-color: transparent;
      border-bottom: 5px solid black;
      transition: all 0.4s ease;
      &.explore {
        background-color: $yellow;
      }
      &.videos {
        background-color: blue;
      }
      &:hover {
        cursor: pointer;
        background-color: $darkwhite;
        color: white;
      }
    }
  }

  .hero {
    min-width: 100%;
    min-height: 80px;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 60px;
    @media only screen and (max-width: 1200px) {
      min-height: 250px;
    }
    .row {
      display: flex;
      flex: 1;
      flex-direction: row;
      @media only screen and (max-width: 1200px) {
        flex-direction: column;
      }
      justify-content: space-around;
      &.vertical {
        flex-direction: column;
      }
      .cell {
        border: 1px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex: 1;
        background-color: $darkgrey;
        padding: 10px 0;
        transition: all 0.4s ease;
        letter-spacing: 2px;
        min-height: 40px;
        &.small {
          letter-spacing: inherit;
          font-size: 10pt;
        }
        &.plain {
          min-height: 10px;
          background-color: black;
          letter-spacing: 1px;
          @media only screen and (max-width: 1200px) {
            min-height: 100px;
          }
        }
        &.yellow {
          color: black !important;
          background-color: $yellow;
        }
        &:hover {
          cursor: pointer;
          background-color: $darkwhite;
          color: white;
        }
        @media only screen and (max-width: 1200px) {
          min-height: 100px;
        }
      }
    }
  }
  .cover-content {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 10em;
    h1,
    h2,
    h3,
    h4,
    h5 {
      text-align: center;
    }
    h1 {
      margin-bottom: -15px;
      margin-top: 30px;
    }
    h5 {
      margin-top: -15px;
    }

    .md-container {
      width: 100%;
      overflow-wrap: break-word;
      // white-space: pre-line;

      ul {
        list-style: none;
      }

      li::before {
        content: "* ";
      }
    }

    // mobile styles, remove overlay buttons
    @media only screen and (max-width: 1200px) {
      font-size: 22pt !important;
      max-width: 100vw;
      padding: 0 40px 80px 40px;
      margin-bottom: 0;
    }

    .verify-tabs {
      background-color: $yellow;
      color: black;
      display: flex;
      flex-direction: column;

      .v-tab {
        display: flex;
        margin: auto;
        justify-content: center;
        align-content: center;
        flex: 1;
      }
    }

    .il-cover-verification-container {
      display: flex;
      flex-direction: column;

      .il-cover-verification {
        .il-video {
          border-radius: 1em;
          background-color: rgba(240, 240, 240, 0.5);
        }
      }
    }
  }

  _::-webkit-full-page-media,
  _:future,
  :root .cover-content {
    max-width: auto;
  }
}

.cover-footer {
  &.disabled {
    display: none;
  }
  position: fixed;
  bottom: 0;
  min-height: 150px;
  min-width: 100%;
  padding: 10px;
  background-color: black;
  display: flex;
  justify-content: center;
  .il-cover-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    max-height: 80px;
    margin-top: 30px;
    background-color: $offwhite;
    color: black;
    transition: all 0.3s ease;
    &:hover {
      cursor: pointer;
      background-color: darken($offwhite, 30%);
      color: black;
    }
  }
}
